<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Add contact number for {{ user.name }}</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="saveContactNumber"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.phone_number"
                    label="Contact Number"
                    placeholder="Enter phone number"
                    :rules="[v => !!v || 'Contact Number is required']"
                    :error="form.errors.has('phone_number')"
                    :error-messages="form.errors.get('phone_number')"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="mt-0 pt-0"
                >
                  <v-checkbox
                    v-model="usePhoneNumberAsAccountNumber"
                    label="Use phone number as account number"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      phone_number: '',
      account_number: '',
      allow_null_internet_plan_id: true,
    }),
    usePhoneNumberAsAccountNumber: true,
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user(value) {
      if (Object.keys(value).length > 0) {
        this.form.update(value)
        this.form.phone_number = this.user.phone_number
        this.form.account_number = this.user.customer.account_number
        this.form.internet_plan_id = this.user.customer.customer_plan ? this.user.customer.customer_plan.internet_plan_id : ''
        this.form.internet_connection_type = this.user.customer.internet_connection_type
        this.form.password = this.user.customer.router_auth_password
        this.form.station_id = this.user.customer.station_id
        this.form.billing_type = this.user.customer.billing_type
        if (this.user.customer.ip_assignment) {
          this.form.mac_address = this.user.customer.mac_address
          this.form.network_address = this.user.customer.ip_assignment.ip_network.network_address
        }
      }
    },
    usePhoneNumberAsAccountNumber(value) {
      if (value) {
        this.form.account_number = this.form.phone_number
      } else {
        this.form.account_number = this.user.customer.account_number
      }
    },
    'form.phone_number': function (value) {
      if (this.usePhoneNumberAsAccountNumber) {
        this.form.account_number = value
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.reset()
    },
    saveContactNumber() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .put(`customers/${this.user.customer.id}`)
          .then(response => {
            this.$toast.success('Customer phone number updated successfully')
            this.$emit('close')
            this.$emit('customer-contact-number-added', response.data)
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
